<template>
  <div>
    <ul class="flext header_title">
      <li><h2>就业创业指导</h2></li>
      <li>已提交指导问题： <span>{{num.all}}</span> 个</li>
      <li>机构/专家已回复： <span>{{num.replay}}</span> 个</li>
      <li>机构/专家未回复： <span>{{num.unReplay}}</span> 个</li>
      <li class="flexs myask" @click="goRouter('/center/shanxiGuide')">
        <img src="~static/center/ask.png" alt="" />
        <p>我要提问</p>
      </li>
    </ul>
    <ul class="list_question ">
      <li class="list_li" v-for="(item,index) in list" :key="index">
        <div @click="goRouter('/center/leaveMessage',item.id),read(item.id)">
          <h3 class="line_clamp1 cursorP" @click="item.guideType = 1">
            <el-badge is-dot class="item" :hidden="item.guideType=='0'?false:true"> {{item.name}}</el-badge>
          </h3>
          <p class="content line_clamp2">
            {{item.content}}
          </p>
        </div>
        <div class="flext">
          <small>提问时间：{{item.createDate}} </small>
          <p class="question1 flexs cursorP" v-if="item.nums == 0">未回答</p>
          <p class="question2 flexs cursorP" v-if="item.nums == 1">已回答</p>
        </div>
      </li>
    </ul>
    <div class="pages">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNum"
          :page-size="page.pageSize"
          layout="prev, pager, next, total, jumper"
          :total="page.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timingStatus: 1,
      list:[],
      num:[],
      page:{
        pageNum:1,
        pageSize:10,
        total:0
      },
      isHide:false,
    };
  },
  methods: {
   async info(){
      let res = await this.$axios.post('/api/app-jycy-henan/henan/guide/list',this.page);
      if (res.data.success){
        this.list = res.data.data.records;
        this.page.total = res.data.data.total;
        this.guideNum();
      }
    },
    guideNum(){
      this.$axios.get('/api/app-jycy-henan/henan/guide/count').then(res =>{
        if (res.data.success){
          this.num = res.data.data;
        }
      })
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: { id: id },
      });
      window.open(href, "_blank");
    },
    read(id){
      this.$axios.get('/api/app-jycy-henan/henan/guide/detail/read/'+id).then(res => {
        if (res.data.success) {}
      })
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.info();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.info();
  }
};
</script>

<style lang="less" scoped>
@import "~assets/css/henan/guideCreate";
</style>
